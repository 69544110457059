<template>
  <div class="search-input">
    <div>
      <i class="iconfont icon-search"></i>
    </div>
    <input type="text" v-model="searchText" @input="(e) => $emit('input', e.target.value)" @blur="change" :placeholder="placeholder">
  </div>
</template>

<script>
export default {
  props: {
    serchText: {type: String, default: ''},
    placeholder: {type: String, default: '请输入'}
  },
  data () {
    return {
      searchText: ''
    }
  },
  methods: {
    change () {
      this.$emit('change', this.searchText)
    }
  }
}
</script>

<style lang="less" scoped>
.search-input {
  border: 1px solid #D9D9D9;
  width: 12rem;
  height: 32px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 0 12px 0 16px;
  input {
    width: calc(100% - 20px);
    margin-left: 16px;
    border: 0px;
  }
  i {
    font-size: 14px;
    color: #707070;
  }
}
</style>